import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const SignUpWithInviteEmail = () => {
  return (
    <Layout>
      <Seo
        title="How to Sign Up With Invite Email from the Accountant"
        description={`Learn how to sign up for Synkli using an invite email from the accountant. Follow our step-by-step guide to set up your account and manage your finances effortlessly.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`sign-up-with-invite-email`} />
    </Layout>
  )
}

export default SignUpWithInviteEmail
